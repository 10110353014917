import { contactSubmit } from './contact.js';
import { header } from './header.js';
import { helpToggles } from './help.js';
import { maps } from './map.js';
import { homeReviews, homeSearch } from './home.js';
import { locationsReviews } from './locations.js';
import { unitFilters, facilityTabs, facilityReviews, facilityCaro, facilityModalCaro, facilityCaroModal, ratesButtonListener, overlayListener, facilityRatesTable, ratesModal, sizeModal, driveUpModal, featuresToggle, sectionToggle, faqsToggle, viewMore } from './facility.js';
import { initReviewsCaro } from './reviews';

if (document.getElementById('contact_form')) {
	contactSubmit();
}

if (document.querySelector('#reviews_container')) {
	initReviewsCaro();
}

header.init();

helpToggles.init();

maps.init();

homeReviews.init();
homeSearch.init();

unitFilters.init();

facilityTabs.init();
facilityReviews.init();
facilityCaro.init();
facilityModalCaro.init();
facilityCaroModal.init();

locationsReviews.init();

facilityRatesTable.init();
ratesModal.init();
sizeModal.init();
driveUpModal.init();
sectionToggle.init();
faqsToggle.init();

if(document.querySelector(".rates_button")) {
	ratesButtonListener();
	overlayListener();
}

if(document.getElementById('features_wrapper')){
	featuresToggle.init();
}

if(document.getElementById('view_more')){
	viewMore.init();
}