export const header = (function(){
	let my = {};

	function _addDoubleClick() {
		[].forEach.call(document.querySelectorAll('.d_click'), (el) => {
			el.addEventListener('click', e => {
				if ('ontouchstart' in document.documentElement || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0) {
					if (!el.hasAttribute('clicked')) {
						e.preventDefault();
						el.setAttribute('clicked', true);
					}
				}
			});
		});
	}

	function _mobileToggle() {
		document.getElementById('site_nav_toggle').addEventListener('click', function(e){
			let btn = e.currentTarget;
			let nav = document.getElementById('site_header_nav');
			if(btn.classList.contains('open')){
				btn.classList.remove('open');
				nav.classList.remove('open');
			}else{
				btn.classList.add('open');
				nav.classList.add('open');
			}
		});

		[].forEach.call(document.querySelectorAll('#site_header_nav .has_drop'), (el) => {
			el.addEventListener('click', ({currentTarget}) => {
				if(currentTarget.classList.contains('open')){
					currentTarget.classList.remove('open');
				}else{
					currentTarget.classList.add('open');
				}
			});
		});
	}

	my.init = function(){
		_addDoubleClick();
		_mobileToggle();
	};

	return my;
})();


