import {
	AutomatitCarousel
} from './automatit_carousel.m';

export const initReviewsCaro = () => {
	var caro = AutomatitCarousel({
		element: document.getElementById('reviews_container'),
		useChevrons: false,
		speed: 300,
		intervalTiming: false
	});
    
	document.querySelector('#reviews_wrapper .left').addEventListener('click', function(){
		caro.slideLeft();
	});
	document.querySelector('#reviews_wrapper .right').addEventListener('click', function(){
		caro.slideRight();
	});

	document.querySelector('#reviews_container').addEventListener('click', e => {
		const clickedButton = e.target.closest('button');

		if(clickedButton.classList.contains('close_overlay')) {
			clickedButton.closest('.card_full_text').removeAttribute('show');
		} else {
			if(clickedButton) {
				const theCard = e.target.closest('.review');
				theCard.querySelector('.card_full_text').setAttribute('show', true);
			}
		}
	});
};