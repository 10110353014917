import {
	AutomatitCarousel
} from './automatit_carousel.m';
import {
	getMapsHelper
} from './util/mapsHelper';

export const unitFilters = (function(){
	let my = {},
		size = null,
		cc = false;

	function _filterUnits(){
		let noUnits = document.getElementById('no_units');
		noUnits.style.display = "block";
		[].forEach.call(document.querySelectorAll('#facility_rates_wrapper .unit_row'), (el) => {
			if(size === null && cc === false){
				el.style.display = 'flex';
				noUnits.style.display = "none";
			}else if (size !== null) {
				let props = el.dataset.prop;
				el.style.display = 'none';
				if(props.includes(size)){
					el.style.display = 'flex';
					noUnits.style.display = "none";
				}
			} else if (cc) {
				let props = (el.dataset.cc_prop === 'true');
				el.style.display = 'none';
				if(props){
					el.style.display = 'flex';
					noUnits.style.display = "none";
				}
			} 
		});
	}

	function _toggleFilters({currentTarget}){
		if(currentTarget.dataset.filter === "all"){
			[].forEach.call(document.querySelectorAll('#facility_filters .filter'), (el) => {
				el.classList.remove("active");
			});
			size = null;
			cc = false;
			currentTarget.classList.add('active');
		}else if (currentTarget.dataset.filter === "cc"){
			let active = currentTarget.parentElement.querySelector('.active');
			active.classList.remove('active');
			currentTarget.classList.add('active');
			size = null;
			cc = true;
		}else{
			let active = currentTarget.parentElement.querySelector('.active');
			active.classList.remove('active');
			currentTarget.classList.add('active');
			size = currentTarget.dataset.filter;
		}

		_filterUnits();
	}

	function _assignListeners(){
		[].forEach.call(document.querySelectorAll('#facility_filters .filter'), (el) => {
			el.addEventListener('click', _toggleFilters);
		});
	}

	my.init = () => {
		if(document.getElementById('facility_size_filters')){
			_assignListeners();
		}

		const searchParams = new URLSearchParams(window.location.search);
		if(searchParams.has('filter')) {
			document.getElementById('filter_'+searchParams.get('filter')).click();
		}
	};

	return my;
})();

export const facilityTabs = (function(){
	let my = {};

	function _toggleTabs({currentTarget}){
		[].forEach.call(document.querySelectorAll('#facility_nav .btn'), (el) => {
			el.classList.remove('active');
		});
		currentTarget.classList.add('active');
		[].forEach.call(document.querySelectorAll('.facility_tab'), (el) => {
			el.classList.remove('active');
		});
		document.getElementById(currentTarget.dataset.target).classList.add('active');
	}

	function _assignListeners(){
		[].forEach.call(document.querySelectorAll('#facility_nav .btn'), (el) => {
			el.addEventListener('click', _toggleTabs);
		});
	}

	my.init = () => {
		if(document.getElementById('facility_nav')) {
			_assignListeners();
		}

	};

	return my;
})();

export const facilityReviews = (function(){
	let my = {},
		ignoreScrollEvent = false;

	function _stringToHTML(string){
		let parser = new DOMParser();
		let doc = parser.parseFromString(string, 'text/html');
		return doc.body.firstChild;
	}

	my.init = function(){
		if(document.querySelector('#facility_reviews')){
			window.addEventListener('scroll', function(){
				if (ignoreScrollEvent) {
					return false;
				}
                
				let footer_height = document.querySelector('footer').offsetHeight;
				if ((window.innerHeight + window.window.pageYOffset) >= document.body.scrollHeight - footer_height){	
					let reviews_parent = document.getElementById('facility_reviews');
					let pages = reviews_parent.dataset.pages;
					let last_page = Number(reviews_parent.getAttribute('data-last-page'));
					let rfac = reviews_parent.dataset.rfac;
					if (pages > last_page){
						last_page++;
						reviews_parent.appendChild(_stringToHTML('<div id="review_listing_page_'+last_page+'" class="review_listing"></div>'));
						reviews_parent.setAttribute('data-last-page', last_page);
						ignoreScrollEvent = true;
						let url = null;
						if(rfac !== ''){
							url = '/reviews-ajax/'+rfac+'?limit=10&page='+last_page;
						}else{
							url = '/reviews-ajax?limit=10&page='+last_page;
						}

						fetch(url, {
							method: 'get',
							headers: {
								"X-Requested-With": 'xmlhttprequest'
							}
						})
							.then(function(response){
								return response.text();
							})
							.then(function(text){
								let list_page = document.getElementById('review_listing_page_'+last_page);
								list_page.innerHTML = text;
								list_page.classList.add('display');
								setTimeout(function(){
									ignoreScrollEvent = false;
								}, 1000);
							})
							.catch(function(ex){
								console.log('Error:', ex);
							});
					}
				}
				return true;
			});
		}
	};

	return my;
})();

export const facilityCaro = (function () {
	let my = {};

	my.init = function () {
		if (document.getElementById('facility_caro')) {

			if (window.slide_images.length > 1) {
				const options = {
					element: document.getElementById('facility_caro'),
					speed: 600,
					images: window.slide_images,
					intervalTiming: 6000,
					imagesAsBackgrounds: true
				};
				let caro = AutomatitCarousel(options);
				document.getElementById('caro_right_chevron').addEventListener('click', () => {
					caro.slideRight();
				});
				document.getElementById('caro_left_chevron').addEventListener('click', () => {
					caro.slideLeft();
				});
			} else {
				let btns = document.getElementById('facility_caro_chevrons');
				btns.parentNode.removeChild(btns);
				const options = {
					element: document.getElementById('facility_caro'),
					speed: 600,
					images: window.slide_images,
					imagesAsBackgrounds: true,
					intervalTiming: 0,
					useChevrons: false
				};
				AutomatitCarousel(options);
			}
		}
	};

	return my;
})();

export const facilityModalCaro = (function () {
	let my = {};

	my.init = function () {
		if (document.getElementById('fac_modal_caro')) {

			if (window.slide_images.length > 1) {
				const options = {
					element: document.getElementById('fac_modal_caro'),
					speed: 600,
					images: window.slide_images,
					intervalTiming: 6000,
					imagesAsBackgrounds: true
				};
				let caro = AutomatitCarousel(options);
				document.getElementById('caro_right_chevron').addEventListener('click', () => {
					caro.slideRight();
				});
				document.getElementById('caro_left_chevron').addEventListener('click', () => {
					caro.slideLeft();
				});
			}
		}
	};

	return my;
})();

export const facilityCaroModal = (function () {
	let my = {};

	function _facilityCaroModal() {
		//Function to toggle modal state
		function toggleModal(){
			document.getElementById('facility_modal_caro').toggleAttribute('open');
		}

		document.getElementById('gallery_toggle').addEventListener('click', function() {
			toggleModal();
		});

		//Close Modal if clicking Close button
		document.getElementById('modal_close_button').addEventListener('click', function() {
			toggleModal();
		});
	}

	my.init = function(){
		_facilityCaroModal();
	};

	return my;
})();

export const ratesButtonListener = () => {
	const rates_button = document.querySelectorAll('.rates_button');
	const rates_overlay = document.querySelector('.rates_overlay');
	const unit_options = document.querySelectorAll('.unit_option');
	const to_rates = document.querySelectorAll('.to_rates');
	const overlay_options = document.querySelector('.overlay_options');

	to_rates.forEach((to_rate) => {
		to_rate.addEventListener('click', (e) => {
			const open_selection = document.querySelector('.overlay_selection');
			toRatesHandler(e.currentTarget, e);
			overlay_options.style.left = '50%';
			overlay_options.style.top = e.pageY/2;

			setTimeout(()=> {
				open_selection.scrollIntoView();
			}, 100);
		});
	});

	unit_options.forEach((unit_option) => {
		
		if(window.innerWidth <= 768) {
			unit_option.addEventListener('click', () => {
				unit_option.querySelector('.unit_data').classList.toggle('active');
			});
		}
	});

	rates_button.forEach((button) => {

		if(!button) return;
		
		button.addEventListener('click', function(el, e) {
			rates_overlay.classList.toggle('open');
			let matchingGroup = null;
			const groupIdentifier = button.getAttribute('data-group');
			const overlay = document.querySelectorAll('.overlay_options');
			overlay.forEach(el => {
				if(groupIdentifier === el.getAttribute('data-group')) {
					matchingGroup = el;
				}
			});
			if(matchingGroup) {
				if(matchingGroup.getAttribute('data-group') === groupIdentifier) {
					matchingGroup.classList.toggle('open');
					setTimeout(()=> {
						scrollInto('.overlay_options');
					}, 100);
				} else {
					toRatesHandler(button, e);
					setTimeout(()=> {
						scrollInto('.overlay_selection');
					}, 100);
				}
			} else {
				toRatesHandler(button, e);
				setTimeout(()=> {
					scrollInto('.overlay_selection');
				}, 100);
			}
		});
	
	});	
};

export const overlayListener = () => {
	const rates_overlay = document.querySelector('.rates_overlay');
	const overlay_close = document.querySelectorAll('.overlay_close');
	const back_button = document.querySelectorAll('.back');

	function closeModals() {
		rates_overlay.classList.remove('open');
		document.querySelectorAll('.overlay_selection').forEach(e => e.classList.remove('open'));
		document.querySelectorAll('.overlay_options').forEach(e => e.classList.remove('open'));
	}

	overlay_close.forEach((overlay) => { 
		overlay.addEventListener('click', () => {
			closeModals();
		});
	});

	back_button.forEach((back) => { 
		back.addEventListener('click', () => {
			closeModals();
		});
	});

	rates_overlay.addEventListener('click', () => {
		closeModals();
	});
};

function scrollInto(e) {
	const element = document.querySelector(e);
	const elementRect = element.getBoundingClientRect();
	const absoluteElementTop = elementRect.top + window.pageYOffset;
	const middle = absoluteElementTop - (window.innerHeight / 4);
	return window.scrollTo(0, middle);
}

// eslint-disable-next-line no-unused-vars
const toRatesHandler = function(el, e){
	const rent_overlay = document.querySelector('.rent_overlay');
	const reserve_overlay = document.querySelector('.reserve_overlay');
	const overlay_options = document.querySelector('.overlay_options.open');

	if(el.hasAttribute('data-inquiry-only')){
		location.href = el.getAttribute('data-inquire');
	}else{
		const rentUrl = el.getAttribute('data-rent');
		const resUrl = el.getAttribute('data-res');

		rent_overlay.href = rentUrl;
		reserve_overlay.href = resUrl;

		if(overlay_options) overlay_options.classList.remove('open');
	}
};

/* eslint-disable no-undef */
export const facilityMap = (function(){
	let my = {},
		map = document.getElementById('facility_map');

	my.init = function(){
		if(document.getElementById('facility_map')){
			const mapHelper = getMapsHelper();
			mapHelper.ready()
				.then(() => {
					const theMap = mapHelper.createMap({
						element: map,
						locationElementSelector: '#facility_map',
						useRichmarker: true,
						markerReducer: el => {
							return {
								lat: el.getAttribute('lat'),
								lng: el.getAttribute('lng'),
								content: `
                        <span class="map_pin">
                            <svg xmlns="http://www.w3.org/2000/svg" width="43.8" height="65.65" viewBox="0 0 43.8 65.65"><g><path d="M21.9,0A21.89,21.89,0,0,0,3.54,33.83L21.9,65.65,40.27,33.83A21.91,21.91,0,0,0,21.9,0"/></g></svg>
                        </span>
                        `
							};
						}
					});

					google.maps.event.addListenerOnce(theMap, 'idle', () => {
						theMap.setZoom(15);
					});
				});
			
			[].forEach.call(document.querySelectorAll('#facility_caro_toggles .toggle'), (btn) => {
				btn.addEventListener('click', ({currentTarget}) => {
					[].forEach.call(document.querySelectorAll('#facility_caro_toggles .toggle'), (el) => {
						el.classList.remove('active');
					});
					currentTarget.classList.add('active');

					map.classList.remove('active');
					if(currentTarget.classList.contains('map')){
						map.classList.add('active');
					}
				});
			});
		}
	};

	return my;
})();


export const facilityRatesTable = (function(){

	let my = {};

	my.init = function () {
		if(document.getElementById('facility_rates_type')){
			const buttonContainer = document.getElementById('facility_rates_type');
			const buttons = document.querySelectorAll('#facility_rates_type button');
			buttonContainer.addEventListener('click', function(e){
				let clickedButton = e.target.closest('button');
				let clickedButtonType = clickedButton.getAttribute('data-rates-view');
				buttons.forEach(button => {
					button.classList.remove('active');
				});
				clickedButton.classList.toggle('active');
				if(clickedButtonType === 'table'){
					document.getElementById('unit_cards_table').classList.add('active');+
					document.getElementById('unit_table').classList.remove('active');
				}
				if(clickedButtonType === 'list'){
					document.getElementById('unit_table').classList.add('active');+
					document.getElementById('unit_cards_table').classList.remove('active');
				}
			});

			buttons[0].click();
		}
	};

	return my;
})();

export const ratesModal = (function(){

	let my = {};

	function _ratesModal() {
		//Function to toggle modal state
		function toggleModal(){
			document.getElementById('facility_modal').toggleAttribute('open');
		}

		//Close Modal if clicking outside inner section
		if(document.getElementById('facility_modal')){
			document.getElementById('facility_modal').addEventListener('click', function(e) {
				let clickedItem = e.target.closest('div');
				if(clickedItem.getAttribute('data-container') && clickedItem.getAttribute('data-container') === 'modal_container'){
					toggleModal();
				}
			});
		}

		const convertButtons = document.querySelectorAll('.convert_btn');
		const rentButton = document.getElementById('rent_button');
		const resButton = document.getElementById('res_button');
		convertButtons.forEach(convertButton => {
			convertButton.addEventListener('click', function(e){
				let clickedButton = e.currentTarget;
				toggleModal();
				let rentLink = clickedButton.getAttribute('data-rent-link');
				let target = clickedButton.getAttribute('data-target');
				let resLink = clickedButton.getAttribute('data-res-link');
				rentButton.href = rentLink;
				rentButton.target = target;
				resButton.href = resLink;
			});
		});
	}

	my.init = function(){
		_ratesModal();
	};

	return my;
})();

export const sizeModal = (function(){

	let my = {};

	function _sizeModal() {
		//Function to toggle modal state
		function toggleModal(){
			document.getElementById('facility_size_modal').toggleAttribute('open');
		}

		//Close Modal if clicking outside inner section
		if(document.getElementById('facility_size_modal')){
			document.getElementById('facility_size_modal').addEventListener('click', function(e) {
				let clickedItem = e.target.closest('div');
				if(clickedItem.getAttribute('data-container') && clickedItem.getAttribute('data-container') === 'modal_container'){
					toggleModal();
				}
			});
		}

		const sizeInfoButtons = document.querySelectorAll('.size_info');
		const sizeInfoSize = document.getElementById('size_info_size');
		const sizeInfoHeading = document.getElementById('size_info_heading');
		const sizeInfoText = document.getElementById('size_info_text');
		const sizeInfoImage = document.getElementById('size_info_img');
		sizeInfoButtons.forEach(sizeInfoButton => {
			sizeInfoButton.addEventListener('click', function(e){
				let clickedButton = e.currentTarget;
				toggleModal();
				let size = clickedButton.getAttribute('data-size');
				let heading = clickedButton.getAttribute('data-heading');
				let text = clickedButton.getAttribute('data-text');
				let image = clickedButton.getAttribute('data-image');
				sizeInfoSize.innerHTML = size;
				sizeInfoHeading.innerHTML = heading;
				sizeInfoText.innerHTML = text;
				sizeInfoImage.src = '/dist/images/what_fits/' + image + '_ic.svg';
			});
		});
	}

	my.init = function(){
		_sizeModal();
	};

	return my;
})();

export const driveUpModal = (function(){

	let my = {};

	function _driveUpModal() {
		//Function to toggle modal state
		function toggleModal(){
			document.getElementById('facility_drive_up_modal').toggleAttribute('open');
		}

		//Close Modal if clicking outside inner section
		if(document.getElementById('facility_drive_up_modal')){
			document.getElementById('facility_drive_up_modal').addEventListener('click', function(e) {
				let clickedItem = e.target.closest('div');
				if(clickedItem.getAttribute('data-container') && clickedItem.getAttribute('data-container') === 'modal_container'){
					toggleModal();
				}
			});
		}

		const driveUpButtons = document.querySelectorAll('.drive_up');
		driveUpButtons.forEach(driveUpButton => {
			driveUpButton.addEventListener('click', function(){
				toggleModal();
			});
		});
	}

	my.init = function(){
		_driveUpModal();
	};

	return my;
})();

export const featuresToggle = (function(){

	let my = {};

	function _featuresToggle() {

		const features = document.querySelectorAll('.feature_item');
		const featureWrapper = document.getElementById('features_wrapper');
		let heading = document.getElementById('feature_heading');
		let text = document.getElementById('feature_text');
		const removeClass = (features) => {
			features.forEach(x => {
				x.classList.remove('active');
			});
		};
		features.forEach(feature => {
			feature.addEventListener('click', function(e){
				let clickedFeature = e.currentTarget;
				removeClass(features);
				clickedFeature.classList.add('active');
				if(clickedFeature.getAttribute('data-feat')){
					featureWrapper.setAttribute('data-feat', clickedFeature.getAttribute('data-feat'));
				} else {
					featureWrapper.removeAttribute('data-feat');
				}
				heading.innerHTML = clickedFeature.getAttribute('data-feat-title');
				text.innerHTML = clickedFeature.getAttribute('data-feat-text');
			});
		});

		features[0].click();
	}

	my.init = function(){
		_featuresToggle();
	};

	return my;
})();

export const sectionToggle = (function(){

	let my = {};

	function _sectionToggle() {

		const sectionToggles = document.querySelectorAll('.section_toggle');
		sectionToggles.forEach(sectionToggle => {
			sectionToggle.addEventListener('click', function(e){
				let clickedSection = e.currentTarget;
				clickedSection.classList.toggle('open');
				clickedSection.parentElement.classList.toggle('open');
			});
		});

		document.querySelector('#facility_faqs .section_toggle').click();	
	}

	
	my.init = function(){
		_sectionToggle();
	};

	return my;
})();

export const faqsToggle = (function(){

	let my = {};

	function _faqsToggle() {

		const faqQuestions = document.querySelectorAll('.faq_question');
		const faqQuestionContainers = document.querySelectorAll('.faq_item');
		const removeClass = (faqQuestionContainers) => {
			faqQuestionContainers.forEach(x => {
				x.classList.remove('open');
			});
		};
		faqQuestions.forEach(faqQuestion => {
			faqQuestion.addEventListener('click', function(e){
				let clickedQuestion = e.currentTarget;
				let clickedQuestionContainer = clickedQuestion.parentElement;
				removeClass(faqQuestionContainers);
				clickedQuestionContainer.classList.toggle('open');
			});
		});

		faqQuestions[0].click();
	}

	my.init = function(){
		_faqsToggle();
	};

	return my;
})();

export const viewMore = (function(){

	let my = {};

	function _viewMore() {
		const viewMoreToggle = document.getElementById('view_more');
		const allUnits = document.querySelectorAll('.unit_row');
		const unitCards = document.querySelectorAll('#unit_cards_table .unit_row');
		const unitRows = document.querySelectorAll('#unit_table .unit_row');
		const filters = document.querySelectorAll('#facility_size_filters .filter');
		const hideUnits = (unitsArray) => {
			for (let i = 0; i < unitsArray.length; i++) {
				if(i > 8){
					unitsArray[i].classList.toggle('hide');
				}
			}
		};

		if(unitCards.length <= 9 || unitRows.length <= 9){
			viewMoreToggle.style.display = 'none';
		} else {

			if(document.getElementById('unit_cards_table')){
				hideUnits(unitCards);
			}
	
			if(document.getElementById('unit_table')){
				hideUnits(unitRows);
			}
	
			viewMoreToggle.addEventListener('click', function(){
				allUnits.forEach(unit => {
					unit.classList.remove('hide');
				});
				viewMoreToggle.style.display = 'none';
			});
	
			filters.forEach(filter => {
				filter.addEventListener('click', function(){
					viewMoreToggle.style.display = 'none';
				});
			});
		}

	}

	my.init = function(){
		_viewMore();
	};

	return my;
})();