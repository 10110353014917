import {
	AutomatitCarousel
} from './automatit_carousel.m';

export const homeReviews = (function(){
	let my = {};

	my.init = function(){
		if(document.querySelector('#index_reviews_caro')) {
			window.onload = () => {
				AutomatitCarousel({
					element: document.getElementById('index_reviews_caro'),
					intervalTiming: 0
				});
				const resizeTimer = setInterval(function(){
					if(document.querySelector('#index_reviews_caro .caro_inner')){
						let minHeight = 0;
						[].forEach.call(document.querySelectorAll('#index_reviews_caro .caro_slide'), function(slide){
							let tempDisplay = slide.style.display;
							slide.style.display = 'block';
							slide.style.marginTop = '-'+(slide.offsetHeight/2)+'px';
							if(slide.offsetHeight > minHeight){
								minHeight = slide.offsetHeight;
							}
							slide.style.display = tempDisplay;
						});
						document.querySelector('#index_reviews_caro .caro_inner').style.height = minHeight + 'px';
						clearInterval(resizeTimer);
					}
				}, 500);
			};
		}
	};

	return my;
})();

/* eslint-disable no-undef */
export const homeSearch = (function(){
	let my = {};

	my.init = function(){
		if(document.getElementById('index_search_form')){
			searchHelper().bindSearchEvent(document.querySelector('#index_search_form'));
		}
	};

	return my;
})();